import React from 'react'
import SEO from '../../components/SEO'

import InviewWrapper from '../../elements/inviewWrapper'

import { HeroVideo } from '../../components/homeHero'
import { HomeIntroText } from '../../components/homeHeaderIntro'
import { OurProcess } from '../../components/homeProcess'
import { Showcase } from '../../components/homeShowcase'
import { Partnerships } from '../../components/homePartnerships'
import { NewsAndMore } from '../../components/homeNews'

const HomePage = ({ pageContext }) => {
	const {
		page: { title, homePageFields },
	} = pageContext

	console.log(homePageFields)

	const {
		heroImage,
		heroCopy,
		heroImageMobile,
		heroVideo,
		newsLinks,
		ourProcessLearnMore,
		ourProcessProcesses,
		ourProcessSubheadingLink,
		pageHeaderCopy,
		pageHeaderSubheadingLink: pageHeaderLink,
		partnershipLink,
		partnershipPartnerships,
		showcaseGallery,
		showcaseLink,
	} = homePageFields

	return (
		<>
			<SEO title={title} />
			<HeroVideo
				video={heroVideo}
				image={heroImage}
				mobileImage={heroImageMobile}
				copy={heroCopy}
			/>
			<InviewWrapper>
				<HomeIntroText subheading={pageHeaderLink} copy={pageHeaderCopy} />
				<OurProcess
					subheading={ourProcessSubheadingLink}
					processes={ourProcessProcesses}
					learnMore={ourProcessLearnMore}
				/>
				<Showcase gallery={showcaseGallery} subheading={partnershipLink} />
				<Partnerships
					gallery={partnershipPartnerships}
					subheading={showcaseLink}
				/>
				<NewsAndMore links={newsLinks} />
			</InviewWrapper>
		</>
	)
}

export default HomePage
